



































import { FloatingFooter, ForgotPasswordView,InputBox, LoadingButton, ResetForgotPasswordView } from "@restofrit/components"
import { NetworkManager, Session } from "@restofrit/networking";
import { PasswordGrantStruct, Token } from "@restofrit/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter
    },
})
export default class LoginView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    email = ""
    password = ""
    loading = false

    mounted() {
        const parts = window.location.pathname.substring(1).split("/");
        if (parts.length == 1 && parts[0] == "reset-password") {
            this.present(new ComponentWithProperties(ResetForgotPasswordView).setDisplayStyle("popup").setAnimated(false))
        }
    }

    async submit() {
        if (this.loading) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            const response = await NetworkManager.server.request({
                method: "POST",
                path: "/auth/token",
                body: new PasswordGrantStruct({
                    username: this.email,
                    password: this.password
                }),
                decoder: Token
            })
            await Session.shared.setToken(response.data)
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    forgot() {
        this.present(new ComponentWithProperties(ForgotPasswordView).setDisplayStyle("sheet"))
    }
    
}
