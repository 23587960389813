
























































































































































































































import { BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, NumberInput, PromotionBox,Spinner,SplitForm, TimeInput, Toolbar, UploadImageButton, WholesalerTheme } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { Notification, PromotionStatusHelper } from "@restofrit/structures";
import { CustomerCategory, CustomerCategoryHelper, Image, Promotion, PromotionButton, PromotionSettings, PromotionStatus, ResolutionFit, ResolutionRequest, TranslatedString, Version, WholesalerPrivate } from "@restofrit/structures";
import { Language } from "@restofrit/structures/esm/dist";
import { Formatter } from "@restofrit/utilities";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { EditLanguageHelper } from "../../classes/EditLanguageHelper";
import EditLanguageSelection from "../components/EditLanguageSelection.vue";
import NotificationEditView from "./NotificationEditView.vue";
import PromotionStatsView from "./PromotionStatsView.vue";

@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        Toolbar,
        BackButton,
        Checkbox,
        TimeInput,
        NumberInput,
        SplitForm,
        PromotionBox,
        UploadImageButton,
        EditLanguageSelection,
        Spinner
    },
})
export default class PromotionEditView extends Mixins(NavigationMixin) {
    /**
     * Pass the wholesaler that you are going to edit (or are going to create)
     */
    @Prop({ required: true })
    promotion!: Promotion

    @Prop({ default: null })
    wholesaler!: WholesalerPrivate | null

    @Prop({ default: false })
    canEdit!: boolean

    patch: AutoEncoderPatchType<Promotion> = Promotion.patch({})

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<Promotion>) => Promise<void>

    saving = false
    errorBox: ErrorBox | null = null
    validator = new Validator()

    overrideWholesalerId: string | null = this.wholesaler && this.promotion.wholesalerId === null ? (this.wholesaler?.id ?? null) : null

    wholesalers: WholesalerPrivate[] = []
    loadingWholesalers = false

    EditLanguageHelper = EditLanguageHelper

    get language() {
        return EditLanguageHelper.language
    }

    mounted() {
        // Reset the editing language every time we edit a promotion, to the language of the user
        const lang = this.wholesaler?.settings.language ?? this.$i18n.locale.slice(0, 2)
        if (Object.values(Language).includes(lang as Language)) {
            EditLanguageHelper.language = lang as Language
        }


        this.updateTheme()

        if (this.wholesaler === null) {
            this.loadWholesalers().catch(console.error)
        }
    }

    formatDateTime(date: Date) {
        return Formatter.dateTime(date)
    }

    editNotification(notification: Notification) {
        this.present({
            components: [
                new ComponentWithProperties(NotificationEditView, {
                    notification,
                    promotion: this.patchedPromotion,
                    callback: (notifications: PatchableArrayAutoEncoder<Notification>) => {
                        this.patchSettings(PromotionSettings.patch({
                            notifications
                        }))
                    }
                })
            ],
            modalDisplayStyle: "sheet"
        })
    }

    newNotification() {
        const arr: PatchableArrayAutoEncoder<Notification> = new PatchableArray()
        const notification = Notification.create({})
        arr.addPut(notification)

        this.present({
            components: [
                new ComponentWithProperties(NotificationEditView, {
                    notification,
                    isNew: true,
                    promotion: this.patchedPromotion,
                    callback: (notifications: PatchableArrayAutoEncoder<Notification>) => {
                        arr.merge(notifications)
                        this.patchSettings(PromotionSettings.patch({
                            notifications: arr
                        }))
                    }
                })
            ],
            modalDisplayStyle: "sheet"
        })
    }

    async loadWholesalers() {
        this.loadingWholesalers = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers",
                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
            })
            this.wholesalers = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingWholesalers = false
    }

    openStats() {
        this.present(new ComponentWithProperties(PromotionStatsView, {
            promotion: this.promotion,
            wholesaler: this.wholesaler
        }).setDisplayStyle("popup"))
    }

    updateTheme() {
        if (!this.wholesaler) {
            return
        }
        WholesalerTheme.loadColors(this.wholesaler, this.$refs.example as HTMLElement)
    }

    get title() {
        if (this.isNew) {
            return this.$t('editPromotion.newPromotionTitle')
        }
        return this.patchedPromotion.settings.title.get(EditLanguageHelper.language)
    }

    get promotionStatusList(){
        return Object.values(PromotionStatus)
    }

    getStatusName(status: PromotionStatus){
        return PromotionStatusHelper.getName(status, this.$i18n as any)
    }

    get categories() {
        return Object.values(CustomerCategory)
    }

    getCategoryName(category: CustomerCategory) {
        return CustomerCategoryHelper.getName(category, this.$i18n as any)
    }

    get isNew() {
        return Object.values(this.promotion.settings.title.translations).length == 0
    }

    get patchedPromotion() {
        return this.promotion.patch(this.patch)
    }

    applyPatch(patch: AutoEncoderPatchType<Promotion>) {
        this.patch = this.patch.patch(patch)
    }

    patchSettings(patch: AutoEncoderPatchType<PromotionSettings>) {
        this.patch = this.patch.patch({
            settings: patch
        })
    }

    patchOverrideSettings(patch: AutoEncoderPatchType<PromotionSettings>, wholesalerId: string | null = null) {
        const id = (wholesalerId ?? this.overrideWholesalerId)
        if (id === null) {
            this.patchSettings(patch)
            return
        }
        const wholesalerOverrides = new Map(this.patchedPromotion.wholesalerOverrides)
        wholesalerOverrides.set(id, wholesalerOverrides.get(id)?.patch(patch) ?? patch)
        this.patch = this.patch.patch({
            wholesalerOverrides
        })
    }

    isChanged() {
        return patchContainsChanges(this.patch, this.promotion, { version: Version })
    }

    shouldNavigateAway() {
        if (!this.isChanged()) {
            return true
        }
        return confirm(this.$t('confirmation.noSave').toString())
    }


    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            const arr: PatchableArrayAutoEncoder<Promotion> = new PatchableArray()
            this.patch.id = this.promotion.id
            arr.addPatch(this.patch)
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    async deleteMe() {
        if (this.saving) {
            return
        }
        if (!confirm(this.$t('confirmation.deletePromotion').toString())) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            const arr: PatchableArrayAutoEncoder<Promotion> = new PatchableArray()
            arr.addDelete(this.promotion.id)
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    // Patch methods
    get promotionTitle() {
        return this.settings.title.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set promotionTitle(title: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            title: TranslatedString.createPatch(title ? title : null, EditLanguageHelper.language)
        }))
    }

    get originalPromotionTitle() {
        const original = this.rfsettings.title.getIfExists(EditLanguageHelper.language) ?? ""
        if (original === this.promotionTitle) {
            return ""
        }
        return original
    }

    get promotionTitleLines() {
        return Math.min(Math.max(1, this.promotionTitle.split("\n").length), 2)
    }

    get subtitle() {
        return this.settings.subtitle.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set subtitle(subtitle: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            subtitle: TranslatedString.createPatch(subtitle ? subtitle : null, EditLanguageHelper.language)
        }))
    }

    get originalSubtitle() {
        const original = this.rfsettings.subtitle.getIfExists(EditLanguageHelper.language) ?? ""
        if (original === this.subtitle) {
            return ""
        }
        return original
    }

    get promotionText() {
        return this.settings.promotionText.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set promotionText(promotionText: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            promotionText: TranslatedString.createPatch(promotionText ? promotionText : null, EditLanguageHelper.language)
        }))
    }

    get originalPromotionText() {
        const original = this.rfsettings.promotionText.getIfExists(EditLanguageHelper.language) ?? ""
        if (original === this.promotionText) {
            return ""
        }
        return original
    }

    get promotionTextLines() {
        return Math.min(Math.max(1, this.promotionText.split("\n").length), 2)
    }

    get promotionSubtext() {
        return this.settings.promotionSubtext.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set promotionSubtext(promotionSubtext: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            promotionSubtext: TranslatedString.createPatch(promotionSubtext ? promotionSubtext : null, EditLanguageHelper.language)
        }))
    }

    get originalPromotionSubtext() {
        const original = this.rfsettings.promotionSubtext.getIfExists(EditLanguageHelper.language) ?? ""
        if (original === this.promotionSubtext) {
            return ""
        }
        return original
    }

    get promotionSubtextStrikethrough() {
        return this.settings.promotionSubtextStrikethrough
    }

    set promotionSubtextStrikethrough(promotionSubtextStrikethrough: boolean) {
        this.patchOverrideSettings(PromotionSettings.patch({
            promotionSubtextStrikethrough
        }))
    }

    get originalPromotionSubtextStrikethrough() {
        return this.rfsettings.promotionSubtextStrikethrough
    }

    get description() {
        return this.settings.description.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set description(description: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            description: TranslatedString.createPatch(description ? description : null, EditLanguageHelper.language)
        }))
    }

    get notificationTitle() {
        return this.patchedPromotion.settings.notificationTitle.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set notificationTitle(notificationTitle: string) {
        this.patchSettings(PromotionSettings.patch({
            notificationTitle: TranslatedString.createPatch(notificationTitle ? notificationTitle : null, EditLanguageHelper.language)
        }))
    }

    get notificationDescription() {
        return this.patchedPromotion.settings.notificationDescription.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set notificationDescription(notificationDescription: string) {
        this.patchSettings(PromotionSettings.patch({
            notificationDescription: TranslatedString.createPatch(notificationDescription ? notificationDescription : null, EditLanguageHelper.language)
        }))
    }

    get status() {
        return this.patchedPromotion.status
    }

    set status(status: PromotionStatus) {
        this.applyPatch(Promotion.patch({
            status
        }))
    }

    get startAt() {
        return this.patchedPromotion.startAt
    }

    set startAt(startAt: Date) {
        this.applyPatch(Promotion.patch({
            startAt
        }))
    }

    get endAt() {
        return this.patchedPromotion.endAt
    }

    set endAt(endAt: Date) {
        this.applyPatch(Promotion.patch({
            endAt
        }))
    }

    get sendNotification() {
        return this.patchedPromotion.notificationAt !== null
    }

    set sendNotification(enable: boolean) {
        if (enable === this.sendNotification) {
            return
        }
        if (enable) {
            this.notificationAt = this.startAt
        } else {
            this.notificationAt = null
        }
    }

    resendNotification() {
        if (this.patchedPromotion.didSendNotification === false) {
            return
        }

        this.applyPatch(Promotion.patch({
            didSendNotification: false
        }))
    }

    get notificationAt() {
        return this.patchedPromotion.notificationAt
    }

    set notificationAt(notificationAt: Date | null) {
        this.applyPatch(Promotion.patch({
            notificationAt
        }))
    }


    get enableOrders() {
        return this.patchedPromotion.settings.enableOrders
    }

    set enableOrders(enableOrders: boolean) {
        this.patchSettings(PromotionSettings.patch({
            enableOrders
        }))
    }

    get enableMin() {
        return this.patchedPromotion.settings.minimumAmount !== null
    }

    set enableMin(enableMin: boolean) {
        if (enableMin === this.enableMin) {
            return
        }
        if (enableMin) {
            this.minimumAmount = 1
        } else {
            this.minimumAmount = null
        }
    }

    get minimumAmount() {
        return this.patchedPromotion.settings.minimumAmount
    }

    set minimumAmount(minimumAmount: number | null) {
        this.patchSettings(PromotionSettings.patch({
            minimumAmount
        }))
    }

    get enableMax() {
        return this.patchedPromotion.settings.maximumAmount !== null
    }

    set enableMax(enableMax: boolean) {
        if (enableMax === this.enableMax) {
            return
        }
        if (enableMax) {
            this.maximumAmount = 1
        } else {
            this.maximumAmount = null
        }
    }

    get maximumAmount() {
        return this.patchedPromotion.settings.maximumAmount
    }

    set maximumAmount(maximumAmount: number | null) {
        this.patchSettings(PromotionSettings.patch({
            maximumAmount
        }))
    }

    get containPicture() {
        return this.patchedPromotion.settings.containImage
    }

    set containPicture(containImage: boolean) {
        this.patchSettings(PromotionSettings.patch({
            containImage
        }))
    }

    get image() {
        return this.patchedPromotion.settings.image
    }
 
    set image(image: Image | null) {
        this.patchSettings(PromotionSettings.patch({
            image
        }))
    }

    get resolutions(): ResolutionRequest[] {
        return [
            ResolutionRequest.create({
                width: 500,
                fit: ResolutionFit.Cover
            }),
            ResolutionRequest.create({
                width: 1500,
                fit: ResolutionFit.Cover
            })
        ]
    }

    // Buttons
    patchButtons(patch: AutoEncoderPatchType<PromotionButton>) {
        this.patchSettings(PromotionSettings.patch({
            button: patch
        }))
    }

    get enableButton() {
        return this.patchedPromotion.settings.button !== null
    }

    set enableButton(enableButton: boolean) {
        if (enableButton === this.enableButton) {
            return
        }
        if (enableButton) {
            this.patchSettings(PromotionSettings.patch({
                button: PromotionButton.create({})
            }))
        } else {
            this.patchSettings(PromotionSettings.patch({
                button: null
            }))
        }
    }

    get usedButtonTitle() {
        return this.patchedPromotion.settings.button?.text.get(EditLanguageHelper.language) ?? ""
    }

    get buttonTitle() {
        return this.patchedPromotion.settings.button?.text.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set buttonTitle(text: string) {
        this.patchButtons(PromotionButton.patch({
            text: TranslatedString.createPatch(text ? text : null, EditLanguageHelper.language)
        }))
    }

    get usedButtonUrl() {
        return this.patchedPromotion.settings.button?.url.get(EditLanguageHelper.language) ?? ""
    }

    get buttonUrl() {
        return this.patchedPromotion.settings.button?.url.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set buttonUrl(url: string) {
        this.patchButtons(PromotionButton.patch({
            url: TranslatedString.createPatch(url ? url : null, EditLanguageHelper.language)
        }))
    }

    get selectedCategories() {
        return this.patchedPromotion.settings.categories
    }

    set selectedCategories(categories: CustomerCategory[]) {
        const patch = PromotionSettings.patch({})
        for (const cat of this.categories) {
            patch.categories.addDelete(cat)
        }
        for (const cat of categories) {
            patch.categories.addPut(cat)
        }
        this.patchSettings(patch)
    }



    get overridePromotionNumber() {
        return this.settings.number
    }

    set overridePromotionNumber(number: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            number
        }))
    }

    getPromotionNumberFor(wholesalerId: string) {
        return this.patchedPromotion.getSettingsFor(wholesalerId).number
    }

    setPromotionNumberFor(wholesalerId: string, number: string) {
        this.patchOverrideSettings(PromotionSettings.patch({
            number
        }), wholesalerId)
    }

    get settings() {
        return this.patchedPromotion.getSettingsFor(this.overrideWholesalerId)
    }

    get rfsettings() {
        return this.patchedPromotion.settings
    }
    
}
