




































import { InputBox, List, ListItem, Spinner } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { WholesalerPrivate } from "@restofrit/structures";
import { ArrayDecoder, Decoder } from "@simonbackx/simple-encoding";
import { ComponentWithProperties, HistoryManager, NavigationController, NavigationMixin, SplitViewController } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import AdminsView from "../admins/AdminsView.vue";
import PromotionsView from "../promotions/PromotionsView.vue";
import WholesalersView from "../wholesalers/WholesalersView.vue";
import WholesalerMenuView from "./WholesalerMenuView.vue";

@Component({
    components: {
        InputBox,
        List,
        ListItem,
        Spinner
    },
})
export default class RootMenuView extends Mixins(NavigationMixin) {
    wholesalers: WholesalerPrivate[] = []
    loading = false

    mounted() {
        HistoryManager.setUrl("/")
        if (this.splitViewController && !this.splitViewController.shouldCollapse()) {
            this.openWholesalers()
        }
        this.load().catch(e => {
            console.error(e)
        })
    }

    async load() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers",
                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
            })
            this.wholesalers = response.data
        } catch (e) {
            console.error(e)
        }
        this.loading = false
    }

    openWholesalers() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(WholesalersView, {})
        }))
        
    }

    openAdmins() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(AdminsView, {})
        }))
    }

    openPromotions() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(PromotionsView, {
                wholesaler: null
            })
        }))
    }

    openWholesaler(wholesaler: WholesalerPrivate) {
        this.present(new ComponentWithProperties(SplitViewController, {
            root:  new ComponentWithProperties(NavigationController, { 
                root: new ComponentWithProperties(WholesalerMenuView, {
                    wholesaler
                })
            })
        }))
    }

    logout() {
        Session.shared.logout()
    }
    
}
