



























import { BackButton, InputBox, List, ListItem, NavigationBar, Spinner } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { WholesalerPrivate } from "@restofrit/structures";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, PatchableArray, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import WholesalerEditView from "./WholesalerEditView.vue";

@Component({
    components: {
        NavigationBar,
        InputBox,
        List,
        ListItem,
        BackButton,
        GeneralErrorsView,
        Spinner
    },
})
export default class WholesalersView extends Mixins(NavigationMixin) {
    wholesalers: WholesalerPrivate[] = []
    errorBox: ErrorBox | null = null
    loading = false

    mounted() {
        this.load().catch(e => {
            console.error(e)
        })
    }

    async load() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers",
                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
            })
            this.wholesalers = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async patchWholesalers(patch: PatchableArrayAutoEncoder<WholesalerPrivate>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/wholesalers",
            body: patch,
            decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
        })
        this.wholesalers = response.data
    }
    
    createNew() {
        const wholesaler = WholesalerPrivate.create({})
        this.show(new ComponentWithProperties(WholesalerEditView, {
            wholesaler,
            callback: async (patch: AutoEncoderPatchType<WholesalerPrivate>) => {
                const arr: PatchableArrayAutoEncoder<WholesalerPrivate> = new PatchableArray()
                arr.addPut(wholesaler.patch(patch))
                await this.patchWholesalers(arr)
            }
        }))
    }

    editWholesaler(wholesaler: WholesalerPrivate) {
        this.show(new ComponentWithProperties(WholesalerEditView, {
            wholesaler,
            callback: async (patch: AutoEncoderPatchType<WholesalerPrivate>) => {
                patch.id = wholesaler.id
                const arr: PatchableArrayAutoEncoder<WholesalerPrivate> = new PatchableArray()
                arr.addPatch(patch)
                await this.patchWholesalers(arr)
            }
        }))
    }
}
