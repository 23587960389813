













































































import { BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, NumberInput, PromotionBox, Spinner, SplitForm, TimeInput, Toolbar, UploadImageButton } from "@restofrit/components";
import { Session } from "@restofrit/networking";
import { CustomerPrivate, Promotion, PromotionStats, WholesalerPrivate } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities";
import { Decoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { exportStats } from "./PromotionExcelExporter";

@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        BackButton,
        Checkbox,
        TimeInput,
        NumberInput,
        SplitForm,
        PromotionBox,
        UploadImageButton,
        Spinner,
        Toolbar
    },
    filters: {
        date: Formatter.dateTime.bind(Formatter)
    }
})
export default class PromotionStatsView extends Mixins(NavigationMixin) {
    /**
     * Pass the wholesaler that you are going to edit (or are going to create)
     */
    @Prop({ required: true })
    promotion!: Promotion

    @Prop({ default: null })
    wholesaler!: WholesalerPrivate | null

    loading = true

    errorBox: ErrorBox | null = null
    stats: PromotionStats | null = null

    created() {
        this.reload().catch(console.error)
    }

    get isAdmin() {
        return Session.shared.user?.permissions?.isAdmin ?? false
    }

    getWholesaler(customer: CustomerPrivate) {
        return this.stats?.wholesalers.find(wholesaler => wholesaler.id == customer.wholesalerId) ?? null
    }

    async reload() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path:  "/dashboard/promotions/"+this.promotion.id+"/stats",
                decoder: PromotionStats as Decoder<PromotionStats>
            })
            this.stats = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }
    
    downloadExcel() {
        if (!this.stats) {
            return
        }
        exportStats(this.promotion, this.stats, this.$i18n, this.isAdmin)
    }
}
