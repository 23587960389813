







import { Language, LanguageHelper } from '@restofrit/structures';
import { ComponentWithProperties, NavigationMixin } from '@simonbackx/vue-app-navigation';
import { Component, Mixins } from "vue-property-decorator";

import { EditLanguageHelper } from '../../classes/EditLanguageHelper';
import LanguageSelectionContextMenu from './LanguageSelectionContextMenu.vue';

@Component
export default class EditLanguageSelection extends Mixins(NavigationMixin) {
    // Make reactive
    EditLanguageHelper = EditLanguageHelper

    get language() {
        return LanguageHelper.getName(EditLanguageHelper.language)
    }

    openDropdown(event) {
        const displayedComponent = new ComponentWithProperties(LanguageSelectionContextMenu, {
            x: event.clientX,
            y: event.clientY + 10,
            setLanguage: (lang: Language) => {
                console.log("set language", lang)
                EditLanguageHelper.language = lang
            }
        });
        this.present(displayedComponent.setDisplayStyle("overlay"));
    }
}
