


































































import { BackButton, ColorInput, InputBox, List, ListItem, LoadingButton, NavigationBar, PromotionBox, SplitForm, Toolbar, UploadImageButton, WholesalerLogo, WholesalerTheme } from "@restofrit/components"
import { Image,Language,LanguageHelper,Promotion, PromotionSettings, ResolutionFit, ResolutionRequest, TranslatedString, Version, WholesalerColors, WholesalerPrivate, WholesalerPrivateSettings, WholesalerSettings } from "@restofrit/structures";
import { AutoEncoderPatchType, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        Toolbar,
        BackButton,
        ColorInput,
        SplitForm,
        UploadImageButton,
        PromotionBox,
        WholesalerLogo
    },
})
export default class WholesalerEditView extends Mixins(NavigationMixin) {
    /**
     * Pass the wholesaler that you are going to edit (or are going to create)
     */
    @Prop({ required: true })
    wholesaler!: WholesalerPrivate

    patch: AutoEncoderPatchType<WholesalerPrivate> = WholesalerPrivate.patch({})

    @Prop({ required: true })
    callback: (patch: AutoEncoderPatchType<WholesalerPrivate>) => Promise<void>

    saving = false
    errorBox: ErrorBox | null = null
    validator = new Validator()

    get title() {
        if (this.isNew) {
            return this.$t('editWholesaler.createNewTitle').toString()
        }
        return this.patchedWholesaler.settings.name
    }

    get languages() {
        return Object.values(Language)
    }

    get isNew() {
        return this.wholesaler.settings.name.length == 0
    }

    get patchedWholesaler() {
        return this.wholesaler.patch(this.patch)
    }

    patchSettings(patch: AutoEncoderPatchType<WholesalerSettings>) {
        this.patch = this.patch.patch({
            settings: patch
        })
        this.updateTheme()
    }

    patchPrivateSettings(patch: AutoEncoderPatchType<WholesalerPrivateSettings>) {
        this.patch = this.patch.patch({
            privateSettings: patch
        })
    }

    get demoPromotion() {
        return Promotion.create({
            settings: PromotionSettings.create({
                title: new TranslatedString({
                    nl: "Demo promotie",
                    fr: "Exemple de promotion"
                }),
                subtitle: new TranslatedString({
                    nl: "Ondertitel",
                    fr: "Sous-titre"
                }),
                promotionText: new TranslatedString({
                    nl: "3 + 1 gratis",
                    fr: "3 + 1 gratuit"
                }),
                promotionSubtext: new TranslatedString({
                    nl: "Ondertitel",
                    fr: "Sous-titre"
                })
            })
        })
    }

    isChanged() {
        return patchContainsChanges(this.patch, this.wholesaler, { version: Version })
    }

    mounted() {
        this.updateTheme()
    }

    updateTheme() {
        WholesalerTheme.loadColors(this.patchedWholesaler, this.$refs.example as HTMLElement)
    }

    shouldNavigateAway() {
        if (!this.isChanged()) {
            return true
        }
        return confirm(this.$t('confirmation.noSave').toString())
    }

    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            await this.callback(this.patch)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    // Patch methods
    get name() {
        return this.patchedWholesaler.settings.name
    }

    set name(name: string) {
        this.patchSettings(WholesalerSettings.patch({
            name
        }))
    }

    get language() {
        return this.patchedWholesaler.settings.language
    }

    set language(language: Language) {
        this.patchSettings(WholesalerSettings.patch({
            language
        }))
    }

    getLanguageName(language: Language) {
        return LanguageHelper.getName(language)
    }

    get email() {
        return this.patchedWholesaler.privateSettings.email
    }

    set email(email: string) {
        this.patchPrivateSettings(WholesalerPrivateSettings.patch({
            email
        }))
    }

    get primaryColor() {
        return this.patchedWholesaler.settings.colors.primary
    }

    set primaryColor(primary: string) {
        this.patchSettings(WholesalerSettings.patch({
            colors: WholesalerColors.patch({
                primary
            })
        }))
    }

    get textColor() {
        return this.patchedWholesaler.settings.colors.dark
    }

    set textColor(dark: string) {
        this.patchSettings(WholesalerSettings.patch({
            colors: WholesalerColors.patch({
                dark
            })
        }))
    }

    get logo() {
        return this.patchedWholesaler.settings.logo
    }
 
    set logo(logo: Image | null) {
        this.patchSettings(WholesalerSettings.patch({
            logo
        }))
    }

    get resolutions(): ResolutionRequest[] {
        return [
            ResolutionRequest.create({
                width: 250,
                fit: ResolutionFit.Cover
            }),
            ResolutionRequest.create({
                width: 500,
                fit: ResolutionFit.Cover
            })
        ]
    }
    
}
