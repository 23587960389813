








































import { BackButton, InputBox, List, ListItem, NavigationBar, SegmentedControl,Spinner } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { PromotionStatusHelper } from "@restofrit/structures";
import { Promotion, PromotionStatus, WholesalerPrivate } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities";
import { ArrayDecoder, Decoder, PatchableArray, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import PromotionEditView from "./PromotionEditView.vue";

@Component({
    components: {
        NavigationBar,
        InputBox,
        List,
        ListItem,
        BackButton,
        GeneralErrorsView,
        Spinner,
        SegmentedControl
    },
})
export default class PromotionsView extends Mixins(NavigationMixin) {
    promotions: Promotion[] = []
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ default: null })
    wholesaler!: WholesalerPrivate | null

    tabs = ["active", "past"]
    tabLabels = [this.$t("promotions.tabs.active"),this.$t("promotions.tabs.archived")]

    selectedTab = this.tabs[0]

    mounted() {
        this.load().catch(e => {
            console.error(e)
        })
    }

    get wholesalerId() {
        return this.wholesaler?.id ?? null
    }

    get filteredPromotions() {
        const now = new Date()
        if (this.selectedTab === "active") {
            return this.promotions.filter(p => p.endAt > now)
        }
        return this.promotions.filter(p => p.endAt <= now)
    }

    get isAdmin() {
        return Session.shared.user?.permissions?.isAdmin ?? false
    }

    getStatusName(status: PromotionStatus) {
        return PromotionStatusHelper.getName(status, this.$i18n as any)
    }

    async load() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: this.wholesaler ? "/dashboard/wholesalers/"+this.wholesaler.id+"/promotions" : "/dashboard/promotions",
                decoder: new ArrayDecoder(Promotion as Decoder<Promotion>)
            })
            this.promotions = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async patchPromotions(patch: PatchableArrayAutoEncoder<Promotion>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: this.wholesaler ? "/dashboard/wholesalers/"+this.wholesaler.id+"/promotions" : "/dashboard/promotions",
            body: patch,
            decoder: new ArrayDecoder(Promotion as Decoder<Promotion>)
        })
        this.promotions = response.data
    }
    
    createNew() {
        const promotion = Promotion.create({
            wholesalerId: this.wholesaler?.id ?? null
        })
        this.show(new ComponentWithProperties(PromotionEditView, {
            promotion,
            wholesaler: this.wholesaler,
            canEdit: true,
            callback: async (arr: PatchableArrayAutoEncoder<Promotion>) => {
                const all: PatchableArrayAutoEncoder<Promotion> = new PatchableArray()
                all.addPut(promotion)
                all.merge(arr)
                await this.patchPromotions(all)
            }
        }))
    }

    editPromotion(promotion: Promotion) {
        this.show(new ComponentWithProperties(PromotionEditView, {
            promotion,
            canEdit: promotion.wholesalerId !== null || this.isAdmin,
            wholesaler: this.wholesaler,
            callback: async (arr: PatchableArrayAutoEncoder<Promotion>) => {
                await this.patchPromotions(arr)
            }
        }))
    }

    promotionDescription(promotion: Promotion) {
        return Formatter.dateTime(promotion.startAt) + " - "+Formatter.dateTime(promotion.endAt)
    }
}
