































































import { BackButton, InputBox, List, ListItem, NavigationBar, SegmentedControl,Spinner } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { CustomerPrivate, CustomerStatus, WholesalerPrivate } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, PatchableArray, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

import CustomerEditView from "./CustomerEditView.vue";

const tabs = ["waiting", "all", "declined"] as const
type TabType = typeof tabs[number]

class SortableColumn {
    id: string
    name: string
    sort: (a: CustomerPrivate, b: CustomerPrivate) => number
    getString: (customer: CustomerPrivate) => string
    small = false

    constructor(data: {
        id: string, 
        name: string, 
        sort: (a: CustomerPrivate, b: CustomerPrivate) => number,
        getString: (customer: CustomerPrivate) => string,
        small?: boolean
    }) {
        this.id = data.id
        this.name = data.name
        this.sort = data.sort
        this.getString = data.getString
        if (data.small !== undefined) {
            this.small = data.small
        }
    }
}

@Component({
    components: {
        NavigationBar,
        InputBox,
        List,
        ListItem,
        BackButton,
        GeneralErrorsView,
        Spinner,
        SegmentedControl
    },
})
export default class CustomersView extends Mixins(NavigationMixin) {
    customers: CustomerPrivate[] = []
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ required: true })
    wholesaler: WholesalerPrivate

    tabs = tabs
    tabLabels = [this.$t('customers.toAcceptTitle'),this.$t('customers.acceptedTitle'), this.$t('customers.blocked')];

    searchQuery = ""

    selectedTab: TabType = this.tabs[0]

    @Watch("selectedTab")
    onTabChanged() {
        this.sortBy = this.columns.find(c => c.id === this.sortBy.id) ?? this.columns[0]
    }

    get columns() {
        const def = [
            new SortableColumn({
                id: "businessName", 
                name: "Bedrijfsnaam",
                sort: (a, b) => a.settings.businessName.localeCompare(b.settings.businessName),
                getString: (customer) => customer.settings.businessName
            }),
            new SortableColumn({
                id: "contactName", 
                name: "Contactpersoon",
                sort: (a, b) => a.settings.contactName.localeCompare(b.settings.contactName),
                getString: (customer) => customer.settings.contactName,
                small: true
            }),
            new SortableColumn({
                id: "email", 
                name: "E-mailadres",
                sort: (a, b) => a.user.email.localeCompare(b.user.email),
                getString: (customer) => customer.user.email
            }),
            new SortableColumn({
                id: "phone", 
                name: "Telefoonnummer",
                sort: (a, b) => a.settings.contactPhone.localeCompare(b.settings.contactPhone),
                getString: (customer) => customer.settings.contactPhone,
                small: true
            }),
        ];

        if (this.selectedTab === "waiting"){
            return [
                ...def,
                new SortableColumn({
                    id: "createdAt", 
                    name: "Sinds",
                    sort: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
                    getString: (customer) => Formatter.date(customer.createdAt),
                    small: true
                })
            ]
        }
        return [
            ...def,
            new SortableColumn({
                id: "number", 
                name: "Klantnummer",
                sort: (a, b) => a.settings.number.localeCompare(b.settings.number),
                getString: (customer) => customer.settings.number,
                small: true
            })
        ]
    }

    sortBy: SortableColumn = this.columns[0]
    sortDirection: "ASC" | "DESC" = "ASC"

    mounted() {
        this.load().catch(e => {
            console.error(e)
        })
    }

    get badges() {
        return [this.toAcceptCustomers.length > 0 ? this.toAcceptCustomers.length.toString() : "", "", ""]
    }

    toggleSort(column: SortableColumn) {
        if (this.sortBy === column) {
            this.sortDirection = this.sortDirection == "ASC" ? "DESC" : "ASC"
        } else {
            this.sortBy = column
            this.sortDirection = "ASC"
        }
    }

    // For an unknown reason, Typescript isn't smart enough to detect that we always return a value...
    // eslint-disable-next-line getter-return
    get tabCustomers() {
        switch (this.selectedTab) {
            case "all":
                return this.customers.filter(c => c.settings.status == CustomerStatus.Accepted)
            case "waiting":
                return this.toAcceptCustomers
            case "declined":
                return this.customers.filter(c => c.settings.status == CustomerStatus.Declined || c.settings.status == CustomerStatus.Blocked)
        }
    }

    get filteredCustomers() {
        if (this.searchQuery.length > 0) {
            return this.tabCustomers.filter(customer => {
                return customer.settings.businessName.toLowerCase().includes(this.searchQuery.toLowerCase())
                    || customer.settings.contactName.toLowerCase().includes(this.searchQuery.toLowerCase())
                    || customer.user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
                    || customer.settings.contactPhone.replace(/\s+/g, "").toLowerCase().includes(this.searchQuery.toLowerCase())
                    || customer.settings.number.replace(/\s+/g, "").toLowerCase().includes(this.searchQuery.toLowerCase())
            })  
        }

        return this.tabCustomers
    }

    get sortedCustomers() {
        if (!this.sortBy) {
            return this.filteredCustomers
        }

        return this.filteredCustomers.sort((a, b) => {
            if (this.sortDirection == "ASC") {
                return this.sortBy.sort(a, b)
            } else {
                return this.sortBy.sort(b, a)
            }
        })
    }

    get toAcceptCustomers() {
        return this.customers.filter(c => c.settings.status == CustomerStatus.Pending)
    }

    get acceptedCustomers() {
        return this.customers.filter(c => c.settings.status === CustomerStatus.Accepted)
    }

    async load() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers/"+this.wholesaler.id+"/customers",
                decoder: new ArrayDecoder(CustomerPrivate as Decoder<CustomerPrivate>)
            })
            this.customers = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async patchCustomers(patch: PatchableArrayAutoEncoder<CustomerPrivate>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/wholesalers/"+this.wholesaler.id+"/customers",
            body: patch,
            decoder: new ArrayDecoder(CustomerPrivate as Decoder<CustomerPrivate>)
        })
        this.customers = response.data
    }
    
    createNew() {
        // todo: also create a new user (not yet possible)
        const customer = CustomerPrivate.create({})
        this.show(new ComponentWithProperties(CustomerEditView, {
            customer,
            wholesaler: this.wholesaler,
            callback: async (patch: AutoEncoderPatchType<CustomerPrivate>) => {
                const arr: PatchableArrayAutoEncoder<CustomerPrivate> = new PatchableArray()
                arr.addPut(customer.patch(patch))
                await this.patchCustomers(arr)
            }
        }))
    }

    editCustomer(customer: CustomerPrivate) {
        this.show(new ComponentWithProperties(CustomerEditView, {
            customer,
            wholesaler: this.wholesaler,
            callback: async (patch: AutoEncoderPatchType<CustomerPrivate>) => {
                patch.id = customer.id
                const arr: PatchableArrayAutoEncoder<CustomerPrivate> = new PatchableArray()
                arr.addPatch(patch)
                await this.patchCustomers(arr)
            }
        }))
    }
}
