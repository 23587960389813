




































































import { BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, NumberInput, PromotionBox, Spinner, SplitForm, TimeInput, Toolbar, UploadImageButton } from "@restofrit/components";
import { Notification, Promotion, TranslatedString, Version } from "@restofrit/structures";
import { AutoEncoderPatchType, PartialWithoutMethods, PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { EditLanguageHelper } from "../../classes/EditLanguageHelper";
import EditLanguageSelection from "../components/EditLanguageSelection.vue";


@Component({
    components: {
        NavigationBar,
        InputBox,
        GeneralErrorsView,
        List,
        ListItem,
        LoadingButton,
        Toolbar,
        BackButton,
        Checkbox,
        TimeInput,
        NumberInput,
        SplitForm,
        PromotionBox,
        UploadImageButton,
        EditLanguageSelection,
        Spinner
    },
})
export default class NotificationEditView extends Mixins(NavigationMixin) {
    /**
        * Pass the wholesaler that you are going to edit (or are going to create)
        */
    @Prop({ required: true })
    notification!: Notification

    @Prop({ required: true })
    promotion!: Promotion

    @Prop({ default: false })
    isNew!: boolean

    patch: AutoEncoderPatchType<Notification> = Notification.patch({})

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<Notification>) => Promise<void>

    saving = false
    errorBox: ErrorBox | null = null
    validator = new Validator()

    EditLanguageHelper = EditLanguageHelper

    get language() {
        return EditLanguageHelper.language
    }

    get viewTitle() {
        if (this.isNew) {
            return this.$t('editNotification.newNotificationTitle')
        }
        return this.$t('editNotification.editNotificationTitle')
    }

    get patchedNotification() {
        return this.notification.patch(this.patch)
    }

    applyPatch(patch: PartialWithoutMethods<AutoEncoderPatchType<Notification>>) {
        this.patch = this.patch.patch(Notification.patch(patch))
    }

    isChanged() {
        return patchContainsChanges(this.patch, this.notification, { version: Version })
    }

    shouldNavigateAway() {
        if (!this.isChanged()) {
            return true
        }
        return confirm(this.$t('confirmation.noSave').toString())
    }

    resendNotification() {
        this.applyPatch({ didSend: false })
    }

    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            const arr: PatchableArrayAutoEncoder<Notification> = new PatchableArray()
            this.patch.id = this.notification.id
            arr.addPatch(this.patch)
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }

    async deleteMe() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errorBox = null

        try {
            const arr: PatchableArrayAutoEncoder<Notification> = new PatchableArray()
            arr.addDelete(this.notification.id)
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.saving = false
    }


    // Fields
    get date() {
        return this.patchedNotification.date
    }

    set date(date: Date) {
        this.applyPatch({ date })
    }

    get ifNotRead() {
        return this.patchedNotification.ifNotRead
    }

    set ifNotRead(ifNotRead: boolean) {
        this.applyPatch({ ifNotRead })
    }

    get title() {
        return this.patchedNotification.title.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set title(title: string) {
        this.applyPatch({ title: TranslatedString.createPatch(title ? title : null, EditLanguageHelper.language) })
    }

    get description() {
        return this.patchedNotification.description.getIfExists(EditLanguageHelper.language) ?? ""
    }

    set description(description: string) {
        this.applyPatch({ description: TranslatedString.createPatch(description ? description : null, EditLanguageHelper.language) })
    }
}
