




























import { BackButton, InputBox, List, ListItem, NavigationBar } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { WholesalerPrivate } from "@restofrit/structures";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, PatchableArray,PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ComponentWithProperties, HistoryManager, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import CustomersView from "../customers/CustomersView.vue";
import PromotionsView from "../promotions/PromotionsView.vue";
import WholesalerEditView from "../wholesalers/WholesalerEditView.vue";

@Component({
    components: {
        InputBox,
        List,
        ListItem,
        BackButton,
        NavigationBar
    },
})
export default class WholesalerMenuView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    wholesaler: WholesalerPrivate

    mounted() {
        HistoryManager.setUrl("/")
        if (this.splitViewController && !this.splitViewController.shouldCollapse()) {
            this.openCustomers()
        }
    }

    openCustomers() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(CustomersView, {
                wholesaler: this.wholesaler
            })
        }))
    }

    openPromotions() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(PromotionsView, {
                wholesaler: this.wholesaler
            })
        }))
    }

    async patchWholesalers(patch: PatchableArrayAutoEncoder<WholesalerPrivate>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/wholesalers",
            body: patch,
            decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
        })
        const wholesalers = response.data

        const wholesaler = wholesalers.find(w => w.id === this.wholesaler.id)
        if (wholesaler) {
            this.wholesaler.set(wholesaler)
        }
    }

    editWholesaler() {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(WholesalerEditView, {
                wholesaler: this.wholesaler,
                callback: async (patch: AutoEncoderPatchType<WholesalerPrivate>) => {
                    patch.id = this.wholesaler.id
                    const arr: PatchableArrayAutoEncoder<WholesalerPrivate> = new PatchableArray()
                    arr.addPatch(patch)
                    await this.patchWholesalers(arr)
                }
            })
        }))
    }

    logout() {
        Session.shared.logout()
    }
    
}
