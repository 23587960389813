






import { AuthenticatedView, PromiseView } from "@restofrit/containers";
import { load } from "@restofrit/i18n";
import { Session } from "@restofrit/networking";
import { Language, WholesalerPrivate } from "@restofrit/structures";
import { ArrayDecoder, Decoder } from "@simonbackx/simple-encoding";
import { ComponentWithProperties, HistoryManager, ModalStackComponent, NavigationController, SplitViewController } from "@simonbackx/vue-app-navigation";
import { Component, Vue } from "vue-property-decorator";

import { EditLanguageHelper } from "./classes/EditLanguageHelper";
import RootMenuView from "./views/menu/RootMenuView.vue";
import WholesalerMenuView from "./views/menu/WholesalerMenuView.vue";
import LoginView from "./views/welcome/LoginView.vue";

@Component({
    components: {
        ModalStackComponent
    },
})
export default class App extends Vue {
    root = new ComponentWithProperties(PromiseView, {
        promise: async () => {
            await load()

            EditLanguageHelper.language = this.$i18n.locale.substr(0, 2) as Language
            return new ComponentWithProperties(AuthenticatedView, {
                root: new ComponentWithProperties(PromiseView, {
                    promise: async () => {
                        try {
                            if (Session.shared.user && Session.shared.user.permissions && Session.shared.user.permissions?.isAdmin) {
                                // Admins always go to the root menu
                                return new ComponentWithProperties(ModalStackComponent, {
                                    root: new ComponentWithProperties(SplitViewController, {
                                        root:  new ComponentWithProperties(NavigationController, { 
                                            root: new ComponentWithProperties(RootMenuView, {})
                                        })
                                    })
                                })
                            }
                            const response = await Session.shared.authenticatedServer.request({
                                method: "GET",
                                path: "/dashboard/wholesalers",
                                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
                            })
                            const wholesalers = response.data

                            if (wholesalers.length == 0) {
                                // Onboarding flow
                                // todo: check if admin -> because we might need to add the first wholesaler
                                if (Session.shared.user && Session.shared.user.permissions && Session.shared.user.permissions?.isAdmin) {
                                    // Still alow
                                } else {
                                    throw new Error("Not authorized")
                                }
                            }

                            EditLanguageHelper.language = wholesalers[0].settings.language

                            return new ComponentWithProperties(SplitViewController, {
                                root:  new ComponentWithProperties(NavigationController, { 
                                    root: new ComponentWithProperties(WholesalerMenuView, {
                                        wholesaler: wholesalers[0]
                                    })
                                })
                            })
                        } catch (e) {
                            console.error(e)
                            Session.shared.temporaryLogout()
                            throw e
                        }
                    }
                }),
                loginRoot:  new ComponentWithProperties(LoginView, {})
            });
        }
    });
    mounted() {
        HistoryManager.activate();
    }
}
