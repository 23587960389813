









import { ContextMenu, ListItem, Radio } from "@restofrit/components";
import { Language, LanguageHelper } from "@restofrit/structures"
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins,Prop } from "vue-property-decorator";
import { EditLanguageHelper } from "../../classes/EditLanguageHelper";


@Component({
    components: {
        ContextMenu,
        ListItem,
        Radio
    }
})
export default class LanguageSelectionContextMenu extends Mixins(NavigationMixin) {
    EditLanguageHelper = EditLanguageHelper

    @Prop({ default: 0 })
    x!: number;

    @Prop({ default: 0 })
    y!: number;

    @Prop()
    setLanguage!: (language: Language) => void;


    get languages() {
        return Object.values(Language)
    }

    get currentLanguage() {
        return EditLanguageHelper.language
    }

    set currentLanguage(lang: Language) {
        this.setLanguage(lang)
        this.pop({ force: true })
    }

    isActive(lang) {
        return this.currentLanguage == lang
    }

    getLanguageName(language: Language) {
        return LanguageHelper.getName(language)
    }
}
